/* ELEMENTS */

/* STRUCTURE */

body {
  background-color: #FAFAFA;
}

body h1 {
  color: #666;
}

body h2 {
  color: #666;
}

body h3 {
  color: #666;
}

body p,
body label,
body th {
  color: #666;
}

body .container {
  background-color: transparent;
}

header {
  background-color: #fff;
}

div.layoutPublic .container-fluid.login {
  border-bottom-color: #1d4f91;
  background-color: #fff;
}

div.layoutPublic .container-fluid.login .container {
  background-color: transparent;
}

div.layoutPublic .container-fluid.login .login-link > a {
  color: #1d4f91;
}

div.layoutPublic .container-fluid.login .login-link > .fa {
  color: #4885b8;
}

.logoPublic {
  background-color: transparent;
}

.logoPublic section.logo {
  background-color: transparent;
}

ol.breadcrumb {
  background-color: #ededed;
}

footer {
  background-color: #FAFAFA;
  min-height: 200px !important;
}

footer .container {
  background-color: #FAFAFA;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px 0 0 0;
}

footer p {
  color: #666;
}

footer a {
  color: #1d4f91;
}

footer a:hover {
  color: #040a12;
}

/* MENU */

.menu-bar {
  background-color: #4885b8;
}

.menu-bar .navbar,
.menu-bar .container {
  background-color: #4885b8;
}

.menu-bar #navbar a,
.menu-bar #navbar .navbar-brand,
.menu-bar #navbar .icon-bar,
.menu-bar li.open a,
.menu-bar li.open .navbar-brand,
.menu-bar li.open .icon-bar,
.menu-bar .navbar a,
.menu-bar .navbar .navbar-brand,
.menu-bar .navbar .icon-bar {
  color: #FAFAFA;
}

.menu-bar #navbar a:hover,
.menu-bar #navbar a:focus,
.menu-bar #navbar a:active,
.menu-bar #navbar .navbar-brand:hover,
.menu-bar #navbar .navbar-brand:focus,
.menu-bar #navbar .navbar-brand:active,
.menu-bar #navbar .icon-bar:hover,
.menu-bar #navbar .icon-bar:focus,
.menu-bar #navbar .icon-bar:active,
.menu-bar li.open a:hover,
.menu-bar li.open a:focus,
.menu-bar li.open a:active,
.menu-bar li.open .navbar-brand:hover,
.menu-bar li.open .navbar-brand:focus,
.menu-bar li.open .navbar-brand:active,
.menu-bar li.open .icon-bar:hover,
.menu-bar li.open .icon-bar:focus,
.menu-bar li.open .icon-bar:active,
.menu-bar .navbar a:hover,
.menu-bar .navbar a:focus,
.menu-bar .navbar a:active,
.menu-bar .navbar .navbar-brand:hover,
.menu-bar .navbar .navbar-brand:focus,
.menu-bar .navbar .navbar-brand:active,
.menu-bar .navbar .icon-bar:hover,
.menu-bar .navbar .icon-bar:focus,
.menu-bar .navbar .icon-bar:active {
  color: #e1e1e1;
}

.menu-bar #navbar ul.dropdown-menu a,
.menu-bar li.open ul.dropdown-menu a,
.menu-bar .navbar ul.dropdown-menu a {
  color: #666;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #FAFAFA;
}

.navbar-default .navbar-toggle {
  border-color: #FAFAFA;
}

#navbar > ul.nav.navbar-nav.navbar-right > li.warning {
  background-color: #c10909;
}

@media only screen and (max-width: 480px) {
  .menu-bar #navbar ul.dropdown-menu a,
  .menu-bar .navbar ul.dropdown-menu a,
  .menu-bar li.open ul.dropdown-menu a {
    color: #fff;
  }
}

#navbar > ul.navbar-nav > li.open > a {
  background-color: #6d9dc6;
}

@media only screen and (min-width: 480px) {
  .dropdown-header {
    color: #1d4f91;
  }
}

@media only screen and (max-width: 480px) {
  .dropdown-header {
    color: #1d4f91;
  }
}

/* LIENS & BOUTONS */

.btn-primary {
  background-color: #1d4f91;
  border-color: #bcd3f2;
}

.btn-primary.btn-primary:hover {
  background-color: #19437c;
  border-color: #2a72d1;
}

a,
.btn-link {
  color: #1d4f91;
}

a:hover,
a:focus,
a:active {
  color: #0c213c;
}

div.layoutPublic .panel-register,
div.layoutPublic .panel-direct {
  border-color: #4885b8;
}

div.layoutPublic .panel-register a,
div.layoutPublic .panel-direct a {
  text-decoration: underline;
}

div.layoutPublic .panel-register a:hover,
div.layoutPublic .panel-direct a:hover {
  text-decoration: none;
}

/* HOMEPAGE */

.panel-login ul li:before {
  color: #1d4f91;
}

/* LIST OF PATIENT RECORD */

.reports-list-right .col-md-4,
.reports-list-right a .col-md-4 {
  background-color: #4885b8;
}

.reports-list-right .col-md-4 .btn-primary,
.reports-list-right a .col-md-4 .btn-primary {
  background-color: #4885b8;
}

.reports-list-right .col-md-4:hover,
.reports-list-right a .col-md-4:hover {
  background-color: #396b94;
}

/* AUTOCOMPLETE / SUGGESTIONS */

.autocomplete-suggestions {
  color: #666;
}

.autocomplete-suggestions strong {
  color: #4885b8;
}

/* CENTER SMALL FORM */

.center-small-form {
  max-width: 500px;
  margin: auto;
}

/* CODE PERSONNALISE CI-DESSOUS... */

.btn-primary {
  border-color: #19437c;
}

