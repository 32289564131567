
$primaryColor: #1d4f91;
$secondaryColor: #4885b8;
$ternaryColor: #FAFAFA;
$darkGrey: #666;
$white: #fff;

/* ELEMENTS */
$publicHeaderBg: $white;
$publicHeaderText: $primaryColor;
$publicHeaderIcon: $secondaryColor;
$publicHeaderBorder: $primaryColor;

$publicLogoBg: transparent;
$publicLogoBgPage: transparent;
$publicPanelBorder: $secondaryColor;

$pageBg: transparent;
$bodyBg: $ternaryColor;
$breadcrumbBg: darken($ternaryColor, 5%);
$text: $darkGrey;
$links: $primaryColor;
$titleH1: $text;
$titleH2: $text;
$titleH3: $text;

$headerBg: $white;
$headerBorder: $primaryColor;
$headerText: $primaryColor;
$headerIcon: $secondaryColor;

$footerBg: $ternaryColor;
$footerText: $text;
$footerLink: $primaryColor;
$footerLinkHover: darken($footerLink, 30%);
$footerBorder: darken($ternaryColor, 10%);

$menuBg: $secondaryColor;
$menuBgSides: $secondaryColor;
$menuText: $ternaryColor;
$menuTextMobile: $white;

$dropdownMenuText: $text;
$dropdownMenuHeaderDesktop: $primaryColor;
$dropdownMenuHeaderMobile: $primaryColor;

@import "../base";

/* CODE PERSONNALISE CI-DESSOUS... */
.btn-primary {
    border-color: darken($primaryColor, 5%); }
