/* STRUCTURE */
body {
    background-color: $bodyBg;
    h1 {
        color: $titleH1; }
    h2 {
        color: $titleH2; }
    h3 {
        color: $titleH3; }
    p, label, th {
        color: $text; }
    .container {
        background-color: $pageBg; } }
header {
    background-color: $headerBg; }
div.layoutPublic .container-fluid.login {
    border-bottom-color: $publicHeaderBorder;
    background-color: $publicHeaderBg;
    .container {
        background-color: transparent; }
    .login-link>a {
        color: $publicHeaderText; }
    .login-link>.fa {
        color: $publicHeaderIcon; } }
.logoPublic {
    background-color: $publicLogoBg;
    section.logo {
        background-color: $publicLogoBgPage; } }

ol.breadcrumb {
    background-color: $breadcrumbBg; }

footer {
    background-color: $footerBg;
    min-height: 200px !important;
    .container {
        background-color: $footerBg;
        border-color: $footerBorder;
        border-style: solid;
        border-width: 1px 0 0 0; }
    p {
        color: $footerText; }
    a {
        color: $footerLink; }
    a:hover {
        color: $footerLinkHover; } }

/* MENU */
.menu-bar {
    background-color: $menuBgSides;
    .navbar, .container {
        background-color: $menuBg; }
    #navbar, li.open, .navbar {
        a, .navbar-brand, .icon-bar {
            color: $menuText;
            &:hover, &:focus, &:active {
                color: darken($menuText, 10%); } }
        ul.dropdown-menu a {
            color: $dropdownMenuText; } } }
.navbar-default .navbar-toggle .icon-bar {
    background-color: $menuText; }

.navbar-default .navbar-toggle {
    border-color: $menuText; }

#navbar > ul.nav.navbar-nav.navbar-right > li.warning {
    background-color: #c10909; }

@media only screen and (max-width : 480px) {
    .menu-bar {
        #navbar, .navbar, li.open {
            ul.dropdown-menu a {
                color: $menuTextMobile; } } } }

#navbar>ul.navbar-nav>li.open>a {
    background-color: lighten($menuBg, 10%); }

@media only screen and (min-width : 480px) {
    .dropdown-header {
        color: $dropdownMenuHeaderDesktop; } }

@media only screen and (max-width : 480px) {
    .dropdown-header {
        color: $dropdownMenuHeaderMobile; } }

/* LIENS & BOUTONS */
.btn-primary {
    background-color: $primaryColor;
    border-color: lighten($primaryColor, 50%);
    &.btn-primary:hover {
        background-color: darken($primaryColor, 5%);
        border-color: lighten($primaryColor, 15%); } }

a, .btn-link {
    color: $links; }

a:hover, a:focus, a:active {
    color: darken($links, 20%); }

div.layoutPublic {
    .panel-register, .panel-direct {
        border-color: $publicPanelBorder;
        a {
            text-decoration: underline; }
        a:hover {
            text-decoration: none; } } }

/* HOMEPAGE */
.panel-login ul li:before {
    color: $primaryColor; }

/* LIST OF PATIENT RECORD */
.reports-list-right, .reports-list-right a {
    .col-md-4 {
        background-color: $secondaryColor;
        .btn-primary {
            background-color: $secondaryColor; }
        &:hover {
            background-color: darken($secondaryColor, 10%); } } }

/* AUTOCOMPLETE / SUGGESTIONS */
.autocomplete-suggestions {
    color: $text;
    & strong {
        color: $secondaryColor; } }
/* CENTER SMALL FORM */
.center-small-form {
    max-width: 500px;
    margin: auto; }

